*,
*::before,
*::after {
    box-sizing: border-box;
    text-decoration: none;
}

/* Custom Properties, update these for your own design */

:root {
    --ff-primary: "Source Sans Pro",sans-serif;
    --ff-secondary: "Source Code Pro",monospace;
    
    
    --fw-reg: 300;
    --fw-bold: 900;
    
    --clr-light: #fff;
    --clr-dark: #212121;
    --clr-accent:  #75b1ff;; 
    
    --fs-h1: 3rem;
    --fs-h2: 2.25rem;
    --fs-h3: 1.25rem;
    --fs-body: 1.20rem;
    
    --bs: 0.25em 0.25em 0.75em rgba(0,0,0,.25),
          0.125em 0.125em 0.25em rgba(0,0,0,.15);
}

@media (min-width: 800px) {
    :root {
        --fs-h1: 4.5rem;
        --fs-h2: 3.75rem;
        --fs-h3: 1.4rem;
        --fs-body: 1.2rem;
    }
}

/* General styles */

body {
    background: var(--clr-light);
    
    color: var(--clr-dark);
    margin: 0;
    font-family: var(--ff-primary);
    font-size: var(--fs-body);
    line-height: 1.6;
    list-style: none;
}

section {
    padding: 5em 2em;
}

img {
    display: block;
    max-width: 100%;
}

strong { font-weight: var(--fw-bold) }

:focus {
    outline: 3px solid var(--clr-accent);
    outline-offset: 3px;
}

/* Buttons */
.maybe {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  

.btn {

    display: inline-block;
    padding: .5em 2.5em;
    background: var(--clr-accent);
    color: var(--clr-dark);
    text-decoration: none;
    cursor: pointer;
    font-size: .8rem;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    font-weight: var(--fw-bold);
    position: relative; 
    transition: transform 200ms ease-in-out;
}

.btn:hover {
    transform: scale(1.1);
}

/* Typography */

h1,
h2,
h3 {
    line-height: 1;
    margin: 0;
}

h1 { font-size: var(--fs-h1) }
h2 { font-size: var(--fs-h2) }
h3 { font-size: var(--fs-h3) }


.section__title {
    margin-bottom: .25em;

}

.section__title--intro {
    font-weight: var(--fw-reg);
}

.section__title--intro strong {
    display: block;
}


.section__subtitle {
    margin: 0;
    font-size: var(--fs-h3);
}

.section__subtitle--intro,
.section__subtitle--about {
    background: var(--clr-accent);
    padding: .25em 1em;
    font-family: var(--ff-secondary);
    margin-bottom: 1em;
}

.section__subtitle--work {
    color: var(--clr-accent);
    font-weight: var(--fw-bold);
    margin-bottom: 2em;
}

/* header */

header {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.logo {
    max-width: 144px;
    padding-left: 10px;
    margin-left: 0px;
}
.logo-pc{
    max-width: 144px;
    padding-left: 10px;
    margin-left: 9px;
}
.navbar {
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.925);

  }

@media (max-width: 1040px){
    .nav__links,.li-in-nav,.li-in-nav::after{
         display: none;
        }
        .hamburger,.lala , .lala2  , .lala3{
            display: block;
        }
       .logo-pc{display: none;}


}
@media (min-width: 1040px){
    .nav__links,.li-in-nav{
         display: flex;
        }
        .logo{
            display: none;
        }
        
        .hamburger,.lala , .lala2  , .lala3{
            display: none;
        }
        .navbar {
    
            position: fixed;
            z-index: 99;
            top: 0;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.925);

          }
}

.nav__links{
    margin-right: 220px;

}
.nav__links ,
.li-in-nav {
   
    text-align: center;
    font-family: var(--ff-primary);
    font-weight: 500;
    font-size: var(--fs-h3);
    color: rgb(48, 48, 48);
    text-decoration: none;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    list-style: none;

}
.li-in-nav{
    display: flex;
    position: relative;
    text-decoration: none;
    color: #111;
    letter-spacing: .5px;
    padding: 0 10px;
    transition: 0.3s ease-in-out; 
    font-size: var(--fs-h3);
    
}
.li-in-nav:hover{
    color: var(--clr-accent);
}
.li-in-nav::after{
    content: "";
    position: absolute;
    background-color: var(--clr-accent);
    font-size: 100%;
    height: 3px;
    width: 0;
    left: 0;
    bottom: -10px;
    transition: 0.45s;
    
}
.li-in-nav:hover::after{
    content: "";
    width: 100%;
    background-color: var(--clr-accent);
    
    
}


.li-in-nav{
margin-left: 40px;
 margin-right: 40px;
 padding-left: 40px;
 padding-right: 40px;
}

.hamburger {
    transition: 0.5s ease-in-out;
    position:absolute;
    top:10px;
    right: 10px;

    z-index: 1;
    user-select: none;
    appearance: none;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    margin-right: 21px;
}
.lala , .lala2  , .lala3{
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background-color: var(--clr-dark);
    border-radius: 6px;
    z-index: 1;
    transform-origin: 0 0 ;
    transition: 0.4s;

}
.hamburger:hover .lala2{
    transform: translateX(10px);
    background-color: var(--clr-accent);
}
.hamburger.is-active .lala {
    transform: translate(0px, -2px) rotate(45deg);
}

.intro {
    position: relative;
    
}



.section__subtitle--intro {
    display: inline-block;
    font-size: 1.5rem;
    

}

@media (min-width: 600px) {
    .intro {
        display: grid;
        width: min-content;
        margin: 0 auto;
        margin-top: 70px;
        grid-column-gap: 1em;
        grid-template-areas: 
            "img title"
            "img subtitle";
        grid-template-columns: min-content max-content;
    }
    
    .intro__img {
        grid-area: img;
        min-width: 250px;
        position: relative;
        z-index: 2;
    }    
    
    .section__subtitle--intro {
        align-self: start;
        grid-column: -1 / 1;
        grid-row: 2;
        text-align: right;
        position: relative;
        left: -1.5em;
        width: calc(100% + 1.5em);
        
    }
}

/*Nav hum */
.navhum-body{
    display: none;
    opacity: 0;
}


/*  My services section  */

.my-services {
    background-color: var(--clr-dark);
    background-image: url('images/background.jpg');
    background-size: cover;
    background-blend-mode: multiply; 
    color: var(--clr-light);

}

.section__title--services {
    color: var(--clr-accent);
    position: relative;
    text-align: center;

}


.section__title--services::after {
    content: '';
    display: block;
    width: 2em;
    height: 1px;
    margin: 0.5em auto 1em;
    background: var(--clr-light);
    opacity: 0.25;
}

.services {
    margin-bottom: 4em;

}

.service {
    max-width: 500px;
    margin: 0 auto;
}
@media (max-width: 800px) {
    .service {
        margin-top: 3.125em;
    }

}
@media (min-width: 800px) {
    .services {
        display: flex;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
    }
    

    .service + .service {
        margin-left: 2em;
    }
}



.about-me {
    max-width: 1150px;
    margin: 0 auto;
}

.about-me__img {
    box-shadow: var(--bs);
}

@media (min-width: 600px) {


    .about-me {
        display: grid;
        grid-template-columns: 1fr 200px;
        grid-template-areas: 
            "title img"
            "subtitle img"
            "text img";
        grid-column-gap: 2em;
    }

    .section__title--about {
        grid-area: title;
    }
    
    .section__subtitle--about {
        grid-column: 1 / -1;
        grid-row: 2;
        position: relative;
        left: -1em;
        width: calc(100% + 2em);
        padding-left: 1em;
        padding-right: calc(200px + 4em);
    }
    
    .about-me__img {
        grid-area: img;
        position: relative;
        z-index: 2;
    }
}

/* My Work */

.my-work {
    background-color: var(--clr-dark);
    color: var(--clr-light);
    text-align: center;    
    padding-top: 158px;
}

.portfolio {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    
}

.portfolio__item {
    background: var(--clr-accent);
    overflow: hidden;
    background-color: black;
}

.portfolio__img {
    transition: 
        transform 500ms cubic-bezier(.5, 0, .5, 1),
        opacity 250ms linear;
}

.portfolio__item:focus {
    position: relative;
    z-index: 2;
    
}

.portfolio__img:hover,
.portfolio__item:focus .portfolio__img {
    transform: scale(1.2);
    opacity: 0.2;
 
}
/*the text over work*/

.text-over-image-container{
    position: relative;
    text-align: center;
  }
  
  
  .text_here {
    font-size: var(--fs-h3);
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--clr-light);
    opacity: 0;
  }
  
  .text-over-image-container:hover .text_here {
    opacity: 1;
  }
  .text-over-image-container:hover .portfolio__item .portfolio__img {
    transform: scale(1.2);
    opacity: 0.2;
}

  


/* footer */

.footer {
    background: #111;
    color: var(--clr-accent);
    text-align: center;
    padding: 1em 0;
    font-size: var(--fs-h3);

}
 
.footer a { 
    color: inherit;
    text-decoration: none;
}

.footer__link {
    font-weight: var(--fw-bold);
}

.footer__link:hover,
.social-list__link:hover {
    opacity: .7;
}

.footer__link:hover {
    text-decoration: underline;
    
}

.social-list {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 2em 0 0;
    padding: 0;
}

.social-list__item {
    margin: 0 .5em;
}

.social-list__link {
    padding: .5em;
}
/* Individual portfolio item styles */

.portfolio-item-individual {
    padding: 0 2em 2em;
    max-width: 1000px;
    margin: 0 auto;
}

.portfolio-item-individual-top {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.portfolio-item-individual p {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}


.copy{
    font-size: 16px;
    color:  var(--clr-light);
}


/*this is the connection section */
.page5{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('images/tele-1.jpg') ;
    background-size: cover;
    
}
.contact, .contant{
    max-width: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 158px;
}
.the-text-in-top{
    display: none;
 }
@media (max-width: 900px) {
    .page5 {
        background-image: url('images/cooperation1.0.jpg') ;
    }
    .contact, .contant{
        display: block;
    }
    .the-text-in-connection{
        display: none;
    }
    .the-text-in-top{
        display: block;
        color: var(--clr-dark);
    }
    .the-text-in-top-sm{
        display: block;
        color: var(--clr-dark);
    }
    .contactInfo{
        color: var(--clr-dark);
    }
    }
    @media (min-width: 900px) {
        .the-text-in-top-sm{
            display: none;
        }

    }
.the-text-in-top{
    text-align: center;
    font-size: 45px;
    text-transform: capitalize;
    margin-bottom: 30px;
}
.the-text-in-top-sm{
    text-align: center;
}
.containerr{
    color: var(--clr-light);
    max-width: 500px;
    line-height: 65px;
    padding-left: 30px;
    font-size: 18px;
}

.box i {
   
    font-size: 28px;
    
}
@media (max-width: 480px) {
    .box i {
   
        font-size:22px
        
    }
}

.contactForm{
    max-width: 700px;
    margin-right: 50px;
}
.containerr, .contactForm{
    flex: 1;
}
.contactForm h2{
    color: var(--clr-light);
    text-align: center;
    font-size: 45px;
    text-transform: capitalize;
    margin-bottom: 30px;
}
.pisp{
    color: var(--clr-light);
    text-align: center;
}
.contactForm .text-box {
    width: 100%;
    float: left;
    background: var(--clr-light);
    border: none;
    height: 50px;
    padding: 12px; 
    font-size: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    opacity: 0.6;
}
.contactForm .text-box :first-child{
    margin-left: 20px;
}
.textt{
    margin-bottom: 20px;
}
.massage{
    background: var(--clr-light);
    width: 100%;
    border: none;
    padding: 12px; 
    font-size: 15px;
    min-height: 200px;
    max-height: 400px;
    resize: vertical;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    opacity: 0.6;
}

.subb{
    position: relative;
    font: bold 1.5em Arial;
    text-decoration: none;
    background: #213140;
    color:  var(--clr-light);
    font-size: 1.5em;
    letter-spacing: 0.1em;
    padding: 10px 45px;
    transition: 0.5s;
    border-top: 1px solid #333333;
    border-right: 1px solid #333333;
    border-bottom: 1px solid #333333;
    border-left: 1px solid #333333;
}
.subb:hover{
    letter-spacing: 0.25em;
    background: var(--clr-accent);
    color: var(--clr-accent);
    box-shadow: 0 0 35px var(--clr-accent);
}
.subb::before{
    content: '';
    position: absolute;
    inset: 2px;
    background: #213140;

}
.send-btn-connetion{
    position: relative;
    z-index: 1;
}
.subb i{
    position: absolute;
    inset: 0;
    display: block;
}
.subb i::before{
    content: '';
    position: absolute;
    top: -3.5px;
    left: 80%;
    width: 14px;
    height: 10px;
    border: 2px solid var(--clr-accent);
    background: #27282c;
    transform: translate(-50%);
    transition: 0.5s;
}
.subb:hover i::before{
    width: 28px;
    left: 20%;
}
.subb i::after{
    content: '';
    position: absolute;
    bottom: -3.5px;
    left: 20%;
    width: 14px;
    height: 10px;
    border: 2px solid var(--clr-accent);
    background: #27282c;
    transform: translate(-50%);
    transition: 0.5s;
    
}
.subb:hover i::after{
    width: 28px;
    left: 80%;
}

.mobile-navbar {
    
    display: flex;
    justify-content: center;
    align-items: center;

}
  
  .wow {
    text-align: center;

  }
  


.thehole {

    width: 250px; /* adjust as needed */
    position: fixed; /* make the navbar stay in the same place */
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #2e2e2e; /* change to your desired color */
    padding: 20px;
    color:white;
  }
  
.Hm-elements {
    list-style: none; /* remove bullet points */
        margin: 0; /* remove default margins */
        padding: 0; /* remove default padding */
        text-align: center;
      }
      

  .Hm-element {
    font-size: var(--fs-h2); /* change to your desired font size */
    margin-bottom: 70px; /* add some spacing between elements */
    margin-top: 25px;;
padding-left: 200px;
padding-right: 200px;
    font-weight: bold;
    display: block;
    width: 100%;
    color:var(--clr-dark);
    transition: ease-in 0.16s;
    transition: ease-out 0.2s;
  }

  .Hm-element:hover{
    color: var(--clr-accent);
    font-size:var(--fs-h1) ;

  }
  
  .Hm-element a {
    color: white; /* change to your desired color */
    text-decoration: none; /* remove underline */
  }
  
  .Hm-element a:hover {
    color: #a8a8a8; /* change to your desired hover color */
    text-decoration: underline;
  }
  .selected{
    color: var(--clr-accent);
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--clr-light);
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999; 
    border-radius: 10px;
    text-align: center;
  }
  
  .popup p {
    font-size: var(--fs-h3);
    margin-bottom: 10px;
  }